body,
html {
  overflow-x: hidden;
  background: linear-gradient(0deg, #6bb6ca 0%, #b7dcdb 16.49%, #ffffe0 88.88%);
  color: $blue;
}
.btn {
  background: #f2b000;
  box-shadow: 4px 8px 0px rgba(242, 176, 0, 0.4);
  border-radius: 20px 48px 28px;
  transition: all 0.15s;
  font-family: $headings-font-family;
  font-weight: 900;
  font-size: 1.25rem;
  text-transform: uppercase;
  border-width: 0;
  &:active,
  &:hover {
    background: #f2b000;
    transform: scale(1.05);
    //box-shadow: 2px 4px 0px rgba(242, 176, 0, 0.4);
  }

  &:active {
    box-shadow: 0px 0px 0px rgba(242, 176, 0, 0.4);
  }

  &.btn-sm {
    font-size: 0.8rem;
    @include media-breakpoint-down(md) {
      font-size: 0.7rem;
    }
  }

  &.btn-link {
    background-color: transparent;
    font-size: 0.8rem;
    box-shadow: none;
    padding: 0;
    margin-top: 10px;
    text-decoration: none;
    text-transform: uppercase;
    color: $blue;
    &:hover {
      transform: none;
      color: $blue;
    }
  }
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  border: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  line-height: 1.3;
  font-weight: 900;
}

.small,
small {
  font-size: 0.7rem;
  @include media-breakpoint-down(md) {
    font-size: 0.7rem;
  }
}

.xsmall {
  font-size: 0.5rem;
}

.lead {
  font-weight: 500;
  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }
}

h1 {
  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
  }
}
h2 {
  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}

h3 {
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

p {
  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
  }

  &.nunito {
    font-family: $headings-font-family;
    font-weight: 700;
  }
}

.btn {
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.form {
  .error {
    .form-control {
      box-shadow: 2px 3px 2px rgba($red, 0.2);
      background-color: rgba($red, 0.2);
    }
    .form-check-input[type="radio"] {
      background-color: rgba($red, 0.2);
    }

    label p {
      color: $red;
    }
  }
}

.form-control {
  font-size: 0.8rem;
  box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.2);
  border-radius: 28px;

  &:focus {
    box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.1);
  }
}

.narrow {
  max-width: 1000px;
}

.narrower {
  max-width: 800px;
}

.header {
  .logo {
    position: relative;
    width: 338px;
    @include media-breakpoint-down(md) {
      width: 200px;
      z-index: 11;
      //position: fixed;
    }
  }
}

.hero {
  background-size: cover;
  background-repeat: no-repeat;

  &__primary {
    background-image: url("/assets/images/_lunaai/bg-home-racek.png");
    background-position: bottom center;

    @include media-breakpoint-down(md) {
      background-image: url("/assets/images/_lunaai/bg-home-racek-mobile.png");
    }
    @include media-breakpoint-up(xl) {
      height: 800px;
    }
  }

  &__secondary {
    background-image: url("/assets/images/_lunaai/bg-result.png");
    background-position: top center;
    padding-top: 180px;
    padding-bottom: 90px;
    @include media-breakpoint-down(md) {
      padding-top: 100px;
      padding-bottom: 50px;
      background-image: url("/assets/images/_lunaai/bg-home-racek-mobile.png");
    }

    .form-check-input {
      font-size: 0.8rem;
    }
  }

  .content {
    width: 60%;
  }
  .counter {
    position: absolute;
    top: 50px;
    right: 0;
    width: 125px;
    height: 125px;
    padding-top: 5px;
    background: radial-gradient(
      86.57% 173.44% at 50% 173.44%,
      #ffffd4 0%,
      #6bb6ca 100%
    );
    border-radius: 80px;
    @include media-breakpoint-down(md) {
      width: 100px;
      height: 100px;
      right: 20px;
      top: 100px;
    }

    span {
      display: block;
      font-size: 0.8rem;
      font-family: "Poppins";
      font-weight: 400;
      color: $white;
      &.strong {
        font-family: "Nunito";
        font-weight: 900;
        font-size: 1.2rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.6rem;

        &.strong {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.form-check-input {
  background-color: transparent;
  border: 2px solid #0d2e55;
}

.form-check-input:checked {
  background-color: #0d2e55;
  border: 2px solid #0d2e55;
  border-color: #0d2e55;
}

.form-check-input:focus {
  border-color: #0d2e55;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.radio-image,
.radio-text {
  p {
    font-family: $headings-font-family;
    font-size: 0.8rem;
    font-weight: 900;
  }
}

.radio-image {
  input[type="radio"] {
    display: none;
  }

  label {
    //border: 3px solid #fff;
    //width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    //border-radius: 50%;
    //background-color: #d3ccf9;
    //padding: 15px;
    display: block;
    position: relative;
    margin: 10px auto;
    cursor: pointer;

    &:hover {
      //background-color: rgba(#9082e1, 0.6);
    }

    @include media-breakpoint-down(md) {
      padding: 10px;
      //margin: 0 0 10px 0;
    }
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
    transform: scale(1);
    margin-bottom: 10px;
  }

  :checked + label {
    //background-color: #9082e1;
  }

  /*   :checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  } */

  :checked + label img {
    transform: scale(1.1) translateY(-10px);
    filter: drop-shadow(2px 3px 2px rgba(209, 94, 20, 0.5));
    //box-shadow: 0 0 5px #333;
    z-index: -1;
  }
  :checked + label p {
    color: $orange-dark;
  }
}

.radio-text {
  input[type="radio"] {
    display: none;
  }

  label {
    //border: 3px solid #fff;
    //width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    position: relative;
    margin: 5px auto;
    padding: 15px;
    cursor: pointer;

    background: #ffffff;
    box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.2);
    border-radius: 18px 32px;

    &:hover {
      background: rgba(209, 94, 20, 0.04);
      box-shadow: 2px 3px 2px rgba(209, 94, 20, 0.5);
    }

    @include media-breakpoint-down(md) {
      padding: 10px 15px;
      margin: 10px auto;
    }

    p {
      margin-bottom: 0;
    }
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    //border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  :checked + label {
    background: rgba(209, 94, 20, 0.04);
    box-shadow: 2px 3px 2px rgba(209, 94, 20, 0.5);
  }

  /* :checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  } */

  :checked + label p {
    transform: scale(1);
    //box-shadow: 0 0 5px #333;
    z-index: -1;
    color: $orange-dark;
  }
}

.message {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $white;
  .sent {
    width: 100px;
  }
}

.filled-icons {
  color: #00c9b7 !important;
}

.btn-outline-pink-dark {
  &:hover {
    background-color: rgba($pink-dark, 0.2);
  }
}

.border-box {
  img {
    width: 60px;
  }

  @include media-breakpoint-up(md) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
}

// Wave animation

.paused {
  animation-play-state: paused !important;
}

.wave {
  background: url(/assets/images/waves-animate.svg) repeat-x;
  background-position: center;
  position: absolute;
  bottom: 0px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -2.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -55px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.backdrop {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(9, 23, 34, 0.95);
  backdrop-filter: blur(2px);

  .bottom-racek {
    position: absolute;
    bottom: -30px;
    right: 0;
    width: 420px;
    animation: float 6s ease-in-out infinite;
    @include media-breakpoint-down(md) {
      width: 200px;
    }

    &__hide {
      opacity: 0;
      animation: float-quick 2s ease-out;
    }
  }

  .bottom-text {
    position: absolute;
    bottom: 20px;
    width: 50%;
  }
}

.rws-backdrop {
  justify-content: center !important;
}

.rws-close {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rws-container {
  background-color: $blue !important;
  /*   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.rws-header {
  color: white;
  text-align: center;
}
.rws-icon {
  background-color: transparent !important;
}

.rws-icon[aria-label="whatsapp"],
.rws-icon[aria-label="reddit"],
.rws-icon[aria-label="telegram"],
.rws-icon[aria-label="mail"],
.rws-icon[aria-label="vk"] {
  display: none;
}

.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 20px;
  z-index: 2;
}

///////////////
// HOME
///////////////

// NAV
.nav-desktop {
  .icon {
    width: 45px;
  }
}

// USPS

.usps {
  img {
    max-width: 90px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Footer

.footer {
  .content {
    position: relative;
    z-index: 1;
  }

  .bg {
    position: relative;
    z-index: 0;
  }

  li {
    font-size: 0.75rem;
    letter-spacing: 0.04em;
  }

  p {
    letter-spacing: 0.01em;
  }
}

// Header
.hamburger-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  padding: 10px;
  z-index: 11;
}

.noscroll {
  overflow: hidden;
  height: 100%;
}

.list-inline-item {
  @include media-breakpoint-down(sm) {
    display: block;
    padding: 15px 0;
  }
}

.cta {
  font-family: $headings-font-family;
  color: $orange;
  text-transform: uppercase;
  position: relative;
  &:hover {
    color: $orange;
  }
  &__arrow {
    &:after {
      transition: all 0.2s ease-in-out;
      content: "";
      position: absolute;
      right: -20px;
      width: 100%;
      height: 100%;
      background: url("/assets/images/_lunaai/icon-arrow.svg") no-repeat center
        right;
    }

    &:hover {
      &:after {
        right: -25px;
      }
    }
  }
}

// Testimonials
.testimonials {
  .item {
    &:hover {
      .cta {
        &__arrow {
          &:after {
            right: -25px;
          }
        }
      }
    }
  }
}

.headline {
  max-width: 450px;
  margin: 0 auto;
}

.remember {
  img {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      height: 600px;
      width: auto;
      transform: translateY(-50%);
      right: -30%;
      z-index: 0;
    }
  }
}

.dogodivscine {
  position: relative;
  z-index: 1;
}

.story {
  margin-bottom: 2rem;
  h1 {
    text-align: center;
    color: $orange;
    margin-bottom: 2rem;
  }
}

.transform-none {
  text-transform: none;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
}

.close {
  cursor: pointer;
}

.react-simple-star-rating {
  svg {
    margin: 0 3px;
  }
}

.cookie-notice {
  position: fixed;
  z-index: 5;
  width: 100%;
  bottom: 10px;
  @include media-breakpoint-down(md) {
    bottom: 0;
  }

  .cookie-wrapper {
    background: #ffffff;
    border-radius: 20px;
    padding: 45px;

    @include media-breakpoint-down(md) {
      padding: 20px;
      border-radius: 0;
    }
  }

  label {
    font-size: 0.75rem;
  }

  .form-check-input {
    font-size: 0.75rem;
  }

  .arrow {
    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.subpage {
  .footer {
    svg {
      display: none;
    }
  }
}

.admin {
  table {
    table-layout: fixed;
    width: 100%;
  }
}

@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

@keyframes float-quick {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
    opacity: 1;
  }
  75% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-30px);
    opacity: 1;
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(450px);
    opacity: 1;
  }
}
