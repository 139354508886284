// Colors
$orange: #f2b000;
$orange-dark: #d15e14;
$blue: #0d2e55;

// TEMP COLORS
$yellow: #fcd201;
$violet: #9082e1;
$violet-light: #dedaf6;
$pink: #ffb3c0;
$pink-dark: #ff5e54;
$turquise: #00c9b7;

$orange-light: #ee968d;
$green: #026b4e;
$green-light: #84a76a;
$green-medium: #4e9783;
$green-dark: #01452b;
$grey: #4a4f54;
$grey-light: #969696;
$gold: #a48242;
$gold-medium: #bfa675;
$gold-light: #e6dece;
$red: rgb(190, 9, 9);

$white: #fff;

$gold-gradient: linear-gradient(180deg, #a48242 0%, #b99450 100%);

$font-family-sans-serif: "Poppins", sans-serif;
$primary: $gold;
$secondary: $green;

$headings-font-family: "Nunito", sans-serif;
$headings-color: $blue;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 7,
  8: $spacer * 7.5,
  9: $spacer * 8,
);

$position-values: (
  0: 0,
  2: 2%,
  10: 10%,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

$theme-colors: (
  "yellow": $yellow,
  "violet": $violet,
  "violet-light": $violet-light,
  "pink": $pink,
  "pink-dark": $pink-dark,
  "turquise": $turquise,
  "blue": $blue,

  "orange": $orange,
  "orange-light": $orange-light,
  "green": $green,
  "green-light": $green-light,
  "green-medium": $green-medium,
  "gree-dark": $green-dark,
  "grey": $grey,
  "gold": $gold,
  "gold-medium": $gold-medium,
  "gold-light": $gold-light,
  "white": $white,
  "red": $red,
);

$headings-font-weight: 700;
$headings-line-height: 1.3;
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.3rem;

$btn-focus-box-shadow: 0;
$btn-font-family: $headings-font-family;
$btn-font-size: 1.3rem;
$btn-padding-x: 2.2rem;
$btn-padding-y: 0.7rem;
//$btn-border-width: 3px;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$border-radius: 7px;
$border-radius-sm: 7px;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $blue;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-btn-font-size-sm: 0.7rem;
$input-border-color: transparent;
$input-border-width: 0;
$input-focus-border-color: $blue;
$input-color: #000;
//$form-floating-label-opacity: 0.5;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 1rem;
$form-check-input-width: 1.5em;
$form-check-input-border: 0;
$form-check-input-border-radius: 50%;

$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
